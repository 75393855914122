@import "_colors";

.editable-content-wrapper {
    width: 100%;
    font-size: 1.6em;
    // height: 1em;
    line-height: 1;
    margin: .25rem auto;
    .editable {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 100%;
        font-size: 1em;
        line-height: 1.5;
        &::before {
            // content: 'Click to add...';
            content: attr(data-placeholder);
            // display: block;
            color: $teal-3;
            font-style: italic;
            font-weight: 300;
        }
        &.has-content {
            &::before {
                content: '';
                display: none;
            }
        }
    }
    .placeholder {
        top: 0;
        left: 0;
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
}