@import "_variables";
@import "_colors";
@import "_mixins";
@import "_breakpoints";

body {
    background-color: #EAEFEF;
}

#chef-wrapper {
    background-color: rgb(234, 239, 239);
    min-height: 100vh;
    position: relative;
    h3 {
      font-size: 1.6rem;
      font-weight: 700;
      margin-bottom: 1.5rem;
    }
    #chef-layout {
        position: relative;
        max-width: 1300px;
        padding-top: calc(100px + 4rem);
        transition: padding-right 0.4s ease-in-out;
        padding-bottom: 2rem;
        margin: 2rem;
        .portal-header-nav {
          overflow: hidden;
          margin-bottom: 2rem;
          margin-top: -2rem;
          display: none;
          .nav-dropdown {
            overflow: hidden;
            margin-left: auto;
            margin-right: auto;
            background-color: $teal-2;
            .dropdown-btn {
              display: flex;
              justify-content: space-between;
              width: 100%;
              align-items: center;
              font-size: 18px;
              font-weight: 600;  
              border: none;
              outline: none;
              color: white;
              padding: 14px 16px;
              background-color: inherit;
              font-family: inherit;
              margin: 0;
              min-width: 135px;
            }
            .dropdown-content {
              display: none;
              position: absolute;
              background-color: #f9f9f9;
              min-width: 135px;
              box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
              z-index: 1;
              a {
                color: black;
                padding: 12px 16px;
                text-decoration: none;
                display: block;
                text-align: left;
              }
              a:hover {
                background-color: $teal-3;
              }
            }
          }
          .nav-dropdown:hover .dropdown-content {
            display: block;
            width: 100%;
          }
        }
        .profile {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: space-between;
    }
      .bio-content {
        flex: 1 1 auto;
        margin: auto 2rem;
        .name-header {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          margin-bottom: 2rem;
          .name-display {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            h1 {
              font-size: 6rem;
              font-weight: 700;
              margin: 0;
              padding: 0;
              margin-right: 1rem;
              font-family: 'Cormorant';
              color: $teal-1;
            }
            .edit-profile {
              margin-left: 1rem;
              font-size: 1.6rem;
              color: $teal-3;
              &:hover {
                cursor: pointer;
                color: $teal-2;
              }
            }
          }
          .pronouns {
            font-weight: 400;
            text-transform: uppercase;
            letter-spacing: .15rem;
            padding-left: .25rem;
            font-size: 1.2rem;
          }
        }
        .header-with-edit {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          h2 {
            margin-bottom: 0;
          }
          .edit-icon {
              margin-left: 1rem;
              font-size: 1.2rem;
              color: $teal-3;
          
              &:hover {
                cursor: pointer;
                color: $teal-2;
              }
            }
        }
        .primary-contact, .short-bio {
          margin-bottom: 3rem;
          h2 {
            color: $teal-2;
            font-size: 1.5rem;
            font-weight: 700;
          }
          .contact-info {
            a {
              color: $gray-5;
              font-weight: 700;
              font-size: 1.6rem;
              text-decoration: none;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
      .bio-sidebar {
        flex: 0 0 300px;
        @include respond-to-max('desktop') {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          margin-bottom: 2rem;
        }
        .profile-photo {
          width: 200px;
          height: 200px;
          border-radius: 100px;
          overflow: hidden;
          margin-bottom: 3rem;
          .no-photo {
            background-color: #B88F63;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            color: white;
            font-weight: 400;
            span {
              &:hover {
                cursor: pointer;
                text-decoration: underline;
              }
            }
            svg {
              font-size: 3rem;
              color: white;
              margin-bottom: 1rem;
            }
          }
          &:hover {
            cursor: pointer;
            opacity: 0.8;
          } 
        }
        .edit-photo {
          color: $teal-2;
          margin-top: -2.5rem;
          margin-bottom: 2rem;
          font-size: 1.1rem;
          font-weight: 400;
          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
        .specialties {
          margin-bottom: 1rem;
          .specialty {
            margin-bottom: 2rem;
          }
          h3 {
            margin-bottom: 0;
            font-weight: 600;
          }
        }
      }
    }

    #chef-drawer {
        position: fixed;
        top: 0;
        right: -320px;
        width: 320px;
        background-color: $teal-2;
        height: calc(100vh);
        z-index: 100;
        transition: right 0.4s ease-in-out;
        padding: 2rem 1.5rem;
        display: flex;
        flex-direction: column;
        color: white !important;
        .closer {
          position: absolute;
          top: 2rem;
          right: 2rem;
          &:hover {
            cursor: pointer;
            opacity: 0.8;
          }
        }
        &.drawer-open {
            right: 0px;
        }
        &.drawer-closed {
            right: -320px;
        }
        .drawer-content {
          margin-top: 2rem;
          position: relative;
          width: 100%;
          height: calc(100vh - 4rem);
          overflow-y: auto;
          .delete-action {
            svg, i {
              margin-right: .5rem;
            }
            margin-top: 3rem;
            font-size: 1.3rem;
            &:hover {
              cursor: pointer;
              text-decoration: underline;
            }
          }
          h3 {
            color: white;
          }
          .allergen-options {
            .allergen {
              color: #DEDEDE;
              background-color: $teal-3a;
              &.active {
                color: $teal-1;
                background-color: white;
              }
            }
          }
          form {
            .form-group {
              .input-wrapper {
                position:relative;
                margin-bottom: 2rem;
                .form-label {
                  color: white;
                  position: absolute;
                  top: 5rem;
                  right: 1rem;
                }
                textarea,
                input {
                  background-color: transparent;
                  border-radius: 0;
                  border-bottom: 1px solid white;
                  color: white;
                  width: 100%;
                }
                select {
                  background-color: transparent;
                  color: white;
                  border-bottom: 1px solid white;
                  border-radius: 0;
                }
              }
              .form-btn-wrapper {
                margin-top: 2rem;
              }
              .sm-txt {
                font-size: 1.1rem;
              }
            }
            .btn {
              background-color: $teal-1;
              &.cancel {
                background-color: $teal-4;
              }
            }
          }
        }
    }
    .form-wrapper {
        background-color: #FFF;
        padding: 2rem;
    }
}

.overview-task {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 4px;
  padding: 1rem 2rem;
  margin-bottom: 2rem;
  min-height: 80px;
  .icon-wrapper {
    flex: 0 0 50px;
    svg, i {
      font-size: 2.5rem;
    }
  }
  .label-wrapper {
    flex: 1 1 auto;
    h4 {
        margin: 0;
        padding: 0;
        font-weight: 550;
        color: $teal-1;
        a {
          color: $teal-1;
          font-size: 2rem;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
  }
  .button-wrapper {
    flex: 0 0 150px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    .complete {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      font-weight: 700;
      svg, i {
        margin-left: 1rem;
      }
    }
    .action-btn {
      color: $teal-1;
      border: 2px solid $teal-1;
      text-decoration: none;
      padding: .5rem 3rem;
      text-align: center;
      border-radius: 20rem;
      font-weight: 400;
      &:hover {
        background-color: $teal-1;
        color: white;
      }
    }
  }
  .icon-label-wrapper {
    display: flex;
    align-items: center;
  }
}

.actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    .lead-action {
        margin-right: 2rem;
        font-size: 2rem;
        position: relative;
        svg, i {
            color: $teal-2;
        }
        &:hover {
            cursor: pointer;
            svg, i {
                color: $teal-1;
            }
        }
    }
}

.chef-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: white;
    min-height: 80px;
    margin-bottom: 2rem;
    border-radius: 4px;
    
    .block-type {
      padding: 0 1rem 0 2rem;
      flex-basis: 150px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      font-size: 1.5rem;
      font-weight: 500;
      a {
        color: $teal-1;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
      svg {
        font-size: 2rem;
        margin-right: 1rem;
      }
    }
    .content {
      flex: 1 1 0;
      padding: 1rem 2rem;
      border-left: thin solid $teal-4;
      border-right: thin solid $teal-4;
      .displays {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

      }
      .display-value {
        flex-basis: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 2rem;
        a {
          color: $teal-1;
        }
        &.half {
          flex-basis: 50%;
        }
        &.two-thirds {
          flex-basis: 66.66%;
        }
        &.one-third {
          flex-basis: 33.33%;
        }
        .label {
          font-size: 1rem;
          text-transform: uppercase;
          color: $teal-3;
          font-weight: 500;
        }
        .value {
          font-size: 2rem;
          font-weight: 400;
          line-height: 1.2;
          @include lineClamp(2);
        }
        &.inline {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          .label {
            margin-right: 1rem;
          }
          .value {
            font-size: 1.5rem;
            font-weight: 500;
          }
        }
      }
    }
    .actions {
      flex-basis: 40px;
      padding: 1rem 0;
      display: flex;
      flex-direction: column;
      position: relative;
      span {
        margin-bottom: .5rem;
        text-align: center;
        svg, i {
          color: $teal-3;
          pointer-events: none;
        }
        &:hover {
          cursor: pointer;
          svg {
            color: $teal-1;
          }
        }
      }
    }
}
.required {
  display: flex;
  background-color: #B88F63;
  align-items: center;
  p {
    color: white;
    margin-bottom: 0;
    font-weight: 400;
    font-size: 1.6rem;
    }
    svg {
    color: white;
    font-size: 3rem;
    margin: 2rem;
    }
}
.header {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  padding: 3rem 0;
  text-align: center;
  // background-image: url('/img/bg/able-cloth-bg.jpg');
    h2 {
      font-family: 'Cormorant';
      font-size: 5rem;
      color: white !important;
      font-weight: 700;
      margin: 0 !important;
    }
    p {
      font-size: 1.6rem;
      line-height: 1.4;
      color: white;
      margin: 0;
      padding: 0 3rem;
      font-weight: 700;
      text-align: center;
    }
}

@include respond-to-max('tablet') {
  #chef-wrapper {
    #chef-layout {
      margin: auto 2rem;
      .portal-header-nav {
        display: block;
        .dropdown-content svg{
          margin-right: .5rem;
        }
        .nav-dropdown.open .dropdown-content {
          display: block;
          width: 100%;
        }
      }
      .content-box {
        align-items: center;
      }
      .profile {
        flex-direction: column-reverse;
        align-items: center;
      }
      .bio-content {
        max-width: 100%;
        .primary-contact .contact-info a {
          font-size: 1.2rem;
        }
        .name-header {
          align-items: center;
          .name-display {
            h1 {
              font-size: 3rem;
            }
          }
        }
        .short-bio {
          display: flex;
          flex-direction: column;
          align-items: center;
          .header-with-edit {
            margin-bottom: 1.5rem;
          }
        }
      }
    }
  }
}

@include respond-to-max('mobile') {
  .overview-task {
    .icon-wrapper {
      margin-left: -1rem;
    }
    .label-wrapper h4 a {
      font-size: 1.6rem;
    }
    .button-wrapper .action-btn {
      padding: .5rem 2rem;
    }
  }
  .required {
    text-align: center;
  }
  .header h2 {
    font-size: 4rem;
      margin: unset;
      margin-bottom: 1rem;
  }
}