@import "_variables";
@import "_colors";
@import "_mixins";
@import "_breakpoints";

.schedule-types {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    div {
        width: 200px;
        height: 120px;
        display: flex;
        flex-direction: column;
        background-color: $teal-4;
        color: $teal-2;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;
        &:hover {
            cursor: pointer;
            color: $teal-1;
            background-color: $teal-3;
        }
    }
}
.blackout-dates {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    .blackout {
        background-color: $teal-4;
        border: 1px solid $teal-3;
        color: $teal-2;
        padding: .5rem 1rem;
        border-radius: 4px;
        margin-right: 1rem;
        font-weight: 400;
        .remove {
            margin-left: 1rem;
            &:hover {
                cursor: pointer;
            }
        }
    }
}

.day-scheduler {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
    flex-wrap: wrap;
    @include respond-to-max('mobile') {
        justify-content: space-between;
    }
    .day-block {
        background-color: $teal-4;
        color: $teal-2;
        width: 80px;
        height: 80px;
        border-radius: 7px;
        margin-right: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        @include respond-to-max('mobile') {
            width: calc(50% - 1rem);
            margin-right: 0;
            margin-bottom: 2rem;
        }
        &:last-child {
            margin-right: 0;
        }
        .day-label {
            font-weight: 700;
            display: flex;
            height: 36px;
            align-items: center;
            justify-content: center;
            width: 100%;
            &.active {
                    background-color: $teal-2;
                    color: white;
                }
            &:hover {
                cursor: pointer;
                background-color: $teal-3;
            }
        }
        .day-night-options {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            .day, .night {
                width: 100%;
                flex-basis: 50%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                padding-top: .7rem;
                height: 44px;
                color: $teal-3;
                &.active {
                        background-color: $teal-2;
                        color: white
                    }
                &:hover {
                    cursor: pointer;
                    background-color: $teal-3
                }
            }
        }
    }
}