@import "_colors";
@import "_mixins";

.menu-manager {
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .menu-block {
        margin: .5rem 0;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        border: thin dashed $teal-3;
        font-size: 1rem;
        position: relative;
        .block-details {
            position: absolute;
            top: 0;
            left: 0;
            font-size: 1rem;
            background-color: $teal-4;
            text-transform: uppercase;
            padding: .2rem;
            cursor: pointer;
            svg {
                margin-left: .5rem;
            }
        }
    }
    .menu-title-block {
        
    }
    .section-title-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        font-size: 1rem;
    }
    .sections-wrapper,
    .items-wrapper {
        width: 100%;
    }
    .add-menu-section {
        &:hover {
            cursor: pointer;
            background-color: $teal-4;
        }
    }
    .add-menu-item {
        font-size: 1.5rem;
        &:hover {
            cursor: pointer;
            background-color: $teal-4;
        }
    }
    .add-item-form {
        font-size: 1.5rem;
    }
}

.editable-content-wrapper {
    &.menu-title {
        font-size: 3rem;
        font-weight: 700;
    }
    &.section-title {
        font-size: 1.6rem;
        font-weight: 400;
    }
    &.item-title {
        font-size: 2rem;
        font-weight: 400;
    }
    &.item-description {
        font-size: 1.8rem;
    }
}

.allergen-options,
.active-allergens {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    .allergen {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: .25rem 1rem;
        border: 1px solid $teal-3;
        background-color: $teal-4;
        margin: 0 .5rem 1rem 0;
        border-radius: 4px;
        font-size: 1.5rem;
        font-weight: 600;
        &.active {
            background-color: $teal-2;
            color: white;
        }
        &:hover {
            cursor: pointer;
            background-color: $teal-3;
            text-decoration: underline;
            // transform: scale(1.1);
        }
    }
}
.active-allergens {
    .allergen {
        &:hover {
            cursor: default;
            background-color: $teal-2;
        }
    }
}