@import "_colors";
@import "_mixins";

.modal {
    .modal-dialog {
        @include shadow;
        background: white;
        position: fixed;
        top: 5vh;
        left: 50%;
        transform: translate(-50%, 0);
        overflow: auto;
        z-index: 5000;
        max-width: 1200px;
        max-height: 960px;
        min-width: 350px;
        max-height: 90vh;

        .modal-content {
            border: none;
            border-radius: 0;
            display: flex;
            flex-direction: row;
            height: 100%;
            width: 100%;
            padding: 4rem;
            position: relative;
            z-index: 200;

            .close-icon {
                position: relative;
                top: -2rem;
                left: 100%;
                z-index: 100;
                font-size: 2rem;
                color: $teal-1;

                &:hover {
                    cursor: pointer;
                    color: $teal-3;
                }
            }
        }
    }

    .modal-backdrop {
        opacity: .6;
        z-index: 1;
        position: fixed;
        width: 100%;
        height: 100vh;
        left: 0;
        top: 0;
        background-color: black;
    }
}