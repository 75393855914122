// Using this file instead of installing another module to manage. These should match the theme file
$breakpoints: (
    'mobile': map-get($grid-breakpoints, 'sm'),
    'tablet': map-get($grid-breakpoints, 'md'),
    'laptop': map-get($grid-breakpoints, 'lg'),
    'desktop': map-get($grid-breakpoints, 'xl'),
    'large': map-get($grid-breakpoints, 'xxl')
) !default;


/// Mixin to manage responsive breakpoints
/// @author Hugo Giraudel
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin respond-to-max($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }
  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin respond-to-min($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }
  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}
