.btn {
    font-size: 1.5rem;
    line-height: 1;
    font-weight: 600;
    letter-spacing: .05em;
    border: 1px solid $teal-2;
    color: white;
    background-color: $teal-2;
    padding: 1.25rem 2.5rem;
    display: inline-block;
    width: fit-content;
    border-radius: 2px;
    text-decoration: none;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    &.add {
        margin-left: 2rem;
    }
    &.centered {
        margin: 0 auto;
        display: block;
    }
    &:hover, &:focus {
        background-color: $teal-1;
        color: white;
        text-decoration: none;
        cursor: pointer;
    }
    &.white {
        background-color: white;
        border-color: white !important;
        color: $teal-1 !important;
        font-weight: 700;
        &:hover {
            background-color: $teal-2;
            border-color: $teal-2 !important;
            color: $teal-4 !important;
        }
        &.bordered {
            border-color: $teal-2 !important;
        }
    }
    &:disabled {
        background: $teal-4;
        border-color: $teal-3 !important;
        color: $teal-2 !important;
        &:hover {
            cursor: default;
            background: $teal-4;
            color: $teal-2 !important;
        }
    }
}
