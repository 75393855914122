body {
    background-color: #EAEFEF;
}

#admin-wrapper {
    background-color: rgb(234, 234, 239);
    min-height: 100vh;
    position: relative;
    #admin-layout {
        position: relative;
        max-width: 1200px;
        padding-top: calc(100px + 4rem);
        margin: 0 auto;
        transition: padding-right 0.4s ease-in-out;
        padding-bottom: 2rem;
    }
    #admin-drawer {
        position: fixed;
        top: 100px;
        right: -220px;
        width: 220px;
        background-color: white;
        height: calc(100vh - 100px);
        z-index: 10;
        transition: right 0.4s ease-in-out;
        padding: 1rem;
        display: flex;
        flex-direction: column;
    }
    &.drawer-open {
        #admin-drawer {
            right: 0px;
        }
        #admin-layout {
            padding-right: 220px;
        }
    }

    &.drawer-closed {
        #admin-drawer {
            right: -220px;
        }
    }

    .form-wrapper {
        background-color: #FFF;
        padding: 2rem;
    }
}