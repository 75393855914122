@import "_colors";

@mixin flexWidth($widths...) {
    @each $width in $widths {
        &.flex-#{round($width)} {
            flex-basis: #{percentage($width/100)};
        }
    }
}

@mixin shadow {
    box-shadow: rgba(18, 79, 84,.2) 0px 5px 15px;
}

@mixin box {
  background-color: white;
  @include shadow;
  padding: 3rem 2rem;
}

@mixin lineClamp($lines: 2) {
    line-clamp: $lines;
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@mixin scrollbars($size: 10px,
    $foreground-color: #666,
    $background-color: #CCC) {

    // For Chrome & Safari
    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background: $foreground-color;
    }

    &::-webkit-scrollbar-track {
        background: $background-color;
    }

    // Standard version (Firefox only for now)
    scrollbar-color: $foreground-color $background-color;
}
