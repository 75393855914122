// Functions first
@import "../../../node_modules/bootstrap/scss/functions";

// Variable overrides second
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1600px
);

// Required Bootstrap imports
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/maps";
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/root";

// Optional
@import "../../../node_modules/bootstrap/scss/utilities";
@import "../../../node_modules/bootstrap/scss/reboot";
@import "../../../node_modules/bootstrap/scss/containers";
@import "../../../node_modules/bootstrap/scss/grid";
@import "../../../node_modules/bootstrap/scss/helpers";
@import "../../../node_modules/bootstrap/scss/utilities/api";

@import "_colors";
@import "_mixins";

#shared-container {
    h2 {
        font-size: 2rem;
        color: $teal-1;
        text-decoration: underline;
        margin-bottom: 1rem;
    }
    .section {
        margin-bottom: 2rem;
    }
    table {
        &.table {
            border-collapse: collapse;
        }
        tr {
            margin-bottom: 0;
        }
        td {
            border: thin solid $teal-3;
            border-left: none;
            &:first-child {
                border-left: thin solid $teal-3;
            }
        }
    }
    .tivity-header {
        margin: 1rem 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .ticket-title {
        display: flex;
        flex-direction: column;
        color: $teal-1;
        font-size: 2rem;
        font-weight: 500;
    }
    .ticket-overview {
        .display-value {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: .5rem;
            .label {
                flex-basis: 150px;
                font-weight: 500;
                font-size: 1.3rem;
            }
            .value {
                flex: 1 1 auto;
            }
        }
    }
}