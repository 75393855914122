/* from dann brand set */
/* primary colors */
$teal-1: #124F54;   /* 18, 79, 84 */
$teal-2: #3B6F73;   /* 29, 111, 115 */
$teal-3: #9BB5B8;   /* 155, 181, 184 */
$teal-3a: rgba(155, 181, 184, 0.5);
$teal-4: #EAEFEF;   /* 234, 239, 239 */


/* secondary colors*/
$navy: #182E2F;     /* 24, 46, 47 */
$green: #567054;    /* 86, 112, 84 */
$peach: #F68C65;    /* 246, 140, 101 */
$sand: #DAA56C;     /* 218, 165, 108 */
$tan: #EFDBC8;      /*  239, 219, 200 */

/* grays */
$gray-1: #111;
$gray-3: #333;
$gray-5: #555;
$gray-7: #777;
