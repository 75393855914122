@import "_variables";
@import "_colors";
@import "_mixins";
@import "_breakpoints";

.photo-rows {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    @include respond-to-max('mobile') {
        justify-content: center;
    }
    .photo-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 1rem;
        margin-right: 1rem;
        @include respond-to-max('mobile') {
            margin-right: 0;
        }
        &:hover {
            opacity: 0.8;
            cursor: pointer;
        }
        // &>span, &>a, &>img, &>div {
        //     flex: 1 1 auto;
        //     width: 20%;
        // }
        // .thumbnail {
        //     width: 100px;
        // }
    }
    .add-photo {
        width: 200px;
        height: 200px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: $teal-4;
        margin-bottom: 1rem;
        margin-right: 1rem;
        @include respond-to-max('mobile') {
            margin-right: 0;
        }
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
}