@import "_colors";
@import "_mixins";

form, .form {
    ::placeholder {
        color: $teal-3 !important;
        font-weight: 400;
    }

    .form-btn-wrapper {
        .cancel {
            background-color: $teal-4;
            border-color: $teal-3;
            color: $teal-2;
            margin-left: 1rem;
            &:hover {
                background-color: $teal-3;
                color: $teal-1;
            }
        }
    }

    .form-group {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        flex-wrap: wrap;

        .center {
            margin: 0 auto;
        }

        .react-datepicker-wrapper {
            display: flex;
            flex-direction: row;
            width: 100%;
            // padding-left: 1rem;
            border: 0;

            .react-datepicker__input-container {
                position: relative;
                display: flex;
                justify-content: center;
                // width: 98%;

                input, button, select, optgroup, textarea {
                    margin: 0;
                    // text-align: center;
                    font-family: inherit;
                    line-height: inherit;
                    width: 100%;
                    font-size: 1.6rem;
                    background-color: #f2f4f5;
                    border: 1px solid transparent;
                } 
            }
        }

        .react-datepicker {
            font-family: inherit;
            font-weight: 400;
            font-size: 0.8rem;
            background-color: #fff;
            color: #000;
            border: 1px solid #aeaeae;
            border-radius: 0.3rem;
            display: inline-block;
            position: relative;

            .react-datepicker__month-container {
                float: left;
                width: 100%;
                .react-datepicker__header {
                    text-align: center;
                    background-color: #f0f0f0;
                    border-bottom: 1px solid #aeaeae;
                    border-top-left-radius: 0.3rem;
                    padding: 8px 0;
                    position: relative;
                }
                .react-datepicker__day--disabled {
                    color: #BBB !important;
                }
                .react-datepicker__day--selected{
                    border-radius: 0.3rem;
                    background-color: $teal-3;
                    color: #fff;
                }
                .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
                    color: #000;
                    display: inline-block;
                    width: 1.7rem;
                    line-height: 1.7rem;
                    text-align: center;
                    margin: 0.8rem;
                }
            }
            .react-datepicker__time-container {
                float: right;
                border-left: 1px solid #aeaeae;
                width: 100%;
                .react-datepicker__time-box {
                    overflow-x: hidden;
                    margin: 0 auto;
                    text-align: center;
                    border-bottom-right-radius: 0.3rem;
                    width: 100%;
                }
            }
        }
        .input-wrapper {
            margin-bottom: .5rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex-basis: 100%;
            padding: 1rem 1rem 1rem 0;
            &.hidden {
                display: none;
            }
            &.standalone {
                padding: 0;
                margin: 0;
            }
            &.inline {
                margin-bottom: 0rem; 
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                flex-basis: 100%;
                padding: 0rem 0rem 0rem 0;
                &.check-form {
                    display: inline-block;
                    flex-direction: row;
                    align-items: center;
                    flex-wrap: nowrap;
                    padding: 0;
                    margin: 0;
                    .form-check-label {
                        font-size: 1.25rem;
                    }
                    .form-check-input {
                        margin-right: 1rem;
                        margin-bottom: 0rem;
                        border-color: $teal-3;
                        &:checked {
                            background-color: $teal-2;
                        }
                    }
                    &.disabled {
                        .form-check-label {
                            color: #aeaeae;
                        }
                        .form-check-input {
                            display: none;
                            }
                        }
                }
            }
            &.check-form {
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                flex-wrap: wrap;
                padding: 0;
                margin: 0;
                .form-check-label {
                    font-size: 1.5rem;
                }
                .form-check-input {
                    margin-right: 2rem;
                    margin-bottom: 1rem;
                    height: 2rem;
                    border-color: $teal-3;
                    &:checked {
                        background-color: $teal-2;
                    }
                }
                &.disabled {
                    .form-check-label {
                        color: #aeaeae;
                    }
                    .form-check-input {
                        display: none;
                        }
                    }
            }
            @include flexWidth(20, 25, 33.33, 50, 66.67, 75, 100);

            input, select {
                appearance: none;
            }    

            label {
                display: block;
                font-weight: 500;
                color: $teal-2;
                letter-spacing: .05em;
                font-size: 1.3rem;
            }
                
            input,
            select {
                font-size: 1.5rem;
                height: 4rem;
            }
            mapbox-address-autofill {
                width: 100%;
            }

            select {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                background-image:
                    linear-gradient(45deg, transparent 50%, $teal-2 50%),
                    linear-gradient(135deg, $teal-2 50%, transparent 50%);
                background-position:
                    calc(100% - 1.5rem) calc(1em + 2px),
                    calc(100% - 1.0rem) calc(1em + 2px);
                background-size:
                    5px 5px,
                    5px 5px;
                background-repeat: no-repeat;
                &:hover, &:focus {
                    cursor: pointer;
                }
            }

    .form-control {
        font-size: 1.6rem;
        font-weight: 400;
        background-color: #f2f4f5;
        border: 1px solid transparent;
        &:active, &:focus {
            background-color: #f2f4f5;
            border: 1px solid $teal-2;
            box-shadow: 0 0 0 0.02rem $teal-2;
        }

    }
     }
     .invalid-feedback {
        display: none;
        }
    }
}
.form {
    margin-top: 0;
}

.inline-edit {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-right: 2rem;

    .form-group {
        flex: 1 1 auto;
        align-items: center;
        flex-wrap: nowrap;
        width: 100%;
        .input-wrapper {
            padding: 0 !important;
            margin: 0;
            // flex: 1 1 auto;
            input, select {
                border: 1px solid $teal-3;
                border-radius: 0;
            }
        }
        .form-label {
            display: none;
        }

        input {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .action-icons {
        flex: 0 0 7rem;
    }

    button {
        flex: 1 1 auto;
        align-items: center;
        justify-content: center;
        border: none;
        border-top: 1px solid $teal-3;
        border-bottom: 1px solid $teal-3;
        color: $teal-2;
        padding: 0;
        font-size: 1.75rem;
        width: 1.75em;
        height: 4rem;
        border-right: 1px solid $teal-3;
        background-color: white;

        &[type="submit"] {
            color: $teal-2;

            &:hover,
            &:focus {
                background-color: $teal-2;
                color: white;
            }
        }

        &.cancel {
            color: $teal-2;

            &:hover,
            &:focus {
                background-color: $teal-2;
                color: white;
            }
        }
    }
}
