// Functions first
@import "../../../node_modules/bootstrap/scss/functions";

// Variable overrides second
@import "_variables";

// Required Bootstrap imports
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/maps";
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/root";

// Optional
@import "../../../node_modules/bootstrap/scss/utilities";
@import "../../../node_modules/bootstrap/scss/reboot";
@import "../../../node_modules/bootstrap/scss/containers";
@import "../../../node_modules/bootstrap/scss/grid";
@import "../../../node_modules/bootstrap/scss/helpers";
@import "../../../node_modules/bootstrap/scss/utilities/api";
@import "../../../node_modules/bootstrap/scss/forms";
@import "../../../node_modules/bootstrap/scss/popover";
@import "../../../node_modules/bootstrap/scss/tooltip";
@import "../../../node_modules/bootstrap/scss/tables";

// Datepicker
@import 'react-datepicker/dist/react-datepicker.css';
// CSS Modules, react-datepicker-cssmodules.css//
@import 'react-datepicker/dist/react-datepicker-cssmodules.css';

//Ours....
@import "_breakpoints";
@import "_colors";
@import "_mixins";
@import "_nav";
@import "_buttons";
@import "_text";
@import "_forms";
@import "_layout";

body, html{
  margin: 0;
  // background: linear-gradient(135deg, rgba(255, 255, 255, 1) 10%, rgba(4, 144, 147, 0.25) 100%);
  // background-repeat: no-repeat;
  // background-color: $green-dark;
  min-height: 100vh;
  font-size: 10px;
  color: $teal-1;
}

div, p {
  font-size: 1.6rem;
}

.sm-txt {
  font-size: 1rem;
}

.content-wrapper {
    @include box;
}

.login {
  width: 400px;
  margin: 0 auto;
  // @include box;
  text-align: center;
  margin-top: 5rem;
}

.box {
    @include box;
}

.clickable {
    &:hover, &:focus {
        cursor: pointer;
        text-decoration: underline;
    }
}

#content {
  padding: 2rem 0;
}

#app-wrapper {
  background: $white;
  padding: 2rem 0;
  min-height: calc(100vh - 79px);
}

#public-wrapper, #preview-wrapper {
  position: relative;
  padding-top: 100px;
  .full-page-bg-img {
    background-color: $teal-2;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
    padding: 2rem 0;
    position: fixed;
    top: 0;
    left: 0;
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-color: $teal-2;
      opacity: .5;
    }
  }
  .form-holder {
    position: relative;
    z-index: 10;
    background: white;
    padding: 2rem;
    margin: 5rem auto;
    max-width: 700px;
    border-radius: 7px;
    .event-form {
      border: 1px solid $teal-2;
      padding: 3rem;
      .form-header {          
        h1 {
          color: $teal-2;
          font-weight: 600;
          font-family: 'Cormorant';
          font-size: 4rem;
        }
        p {
          font-weight: 400;
          color: #232323;
        }
        text-align: center;
      }
    }
    a {
      color: $teal-2;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

#preview-wrapper {
  padding: 0 15px;
  .preview-holder {
    @include box;
    position: relative;
    z-index: 2;
    margin: 100px auto;
    padding-left: 90px;
    padding-right: 90px;
    padding-bottom: 90px;
    max-width: 900px;
    &.chef {
      .photo {
        border-radius: 50%;
        overflow: hidden;
        width: 200px;
        height: 200px;
        background-color: $navy;
        margin: -100px auto 25px auto;
      }
      h1 {
        text-align: center;
        font-family: 'Cormorant';
        font-size: 6rem;
      }
      .content {
        color: $navy;
        .details {
          display: flex;
          flex-direction: row;
          .body {
            flex: 1 1 0;
          }
          .tags {
            flex: 0 0 200px;
            font-size: 1.2rem;
            margin-left: 6rem;
            label {
              font-weight: 600;
              text-transform: uppercase;
              letter-spacing: .1em;
              margin-bottom: 1rem;
              margin-top: 2rem;
              &:first-child {
                margin-top: 0;
              }
            }
            div {
              margin-bottom: 1rem;
              font-weight: 500;
              font-size: 1.2rem;
            }
          }
      }
    }
    @include respond-to-max('tablet') {
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 60px;
      .content {
        .details {
          flex-direction: column;
          .tags {
            margin-left: 0;
            margin-top: 2rem;
            flex: 1 1 100%;
          }
        }
      }
    }
  }
  }
}

#public-wrapper {
  margin: auto 1rem;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  img {
    width: 75px;
    margin-right: 2rem;
  }
}

.event-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 3px solid $teal-3;
  background-color: white;
  min-height: 100px;
  margin-bottom: 2rem;
  &.unstarted {
    background-color: $teal-3;
    min-height: 80px;
    .block-type {
      display: block;
      margin: auto;
    }
  }
  .block-type {
    padding: 0 1rem 0 2rem;
    flex-basis: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.5rem;
    font-weight: 500;
    a {
      color: $teal-1;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    svg {
      font-size: 2rem;
      margin-right: 1rem;
    }
  }
  .content {
    flex: 1 1 0;
    padding: 1.5rem 2rem;
    border-left: thin solid $teal-4;
    border-right: thin solid $teal-4;
    .displays {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      &.compressed {
        .expanded-only {
          display: none;
        }
      }
    }
    .display-value {
      flex-basis: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-bottom: 2rem;
      a {
        color: $teal-1;
      }
      &.half {
        flex-basis: 50%;
      }
      &.two-thirds {
        flex-basis: 66.66%;
      }
      &.one-third {
        flex-basis: 33.33%;
      }
      .label {
        font-size: 1rem;
        text-transform: uppercase;
        color: $teal-3;
        font-weight: 500;
      }
      .value {
        font-size: 2rem;
        font-weight: 400;
        line-height: 1.2;
        @include lineClamp(2);
      }
      &.inline {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        .label {
          margin-right: 1rem;
        }
        .value {
          font-size: 1.5rem;
          font-weight: 500;
        }
      }
    }
  }
  .actions {
    flex-basis: 40px;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    position: relative;
    span {
      margin-bottom: .5rem;
      text-align: center;
      svg, i {
        color: $teal-3;
        pointer-events: none;
      }
      &:hover {
        cursor: pointer;
        svg {
          color: $teal-1;
        }
      }
    }
  }
}

.ticket-section-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: $teal-3;
  padding: 2rem;
  margin-bottom: 1rem;
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-basis: 175px;
    svg, i {
      margin-right: 1rem;
    }
    span {
      font-size: 2.25rem;
      font-weight: 400;
    }
  }
  .meta-content {
    flex: 1 1 0;
    .meta-value {
      margin-right: 1rem;
      color: black;
      font-size: 2rem;
      font-weight: 500;
    }
    .icon-btn {
      color: $teal-2;
      margin-right: 1rem;
      svg, i {
        pointer-events: none;
      }
      &:hover {
        cursor: pointer;
        color: $teal-1;
      }
    }
    
  }
  .status {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    .label {
      font-size: 1.5rem;
      font-weight: 500;
      margin-right: 1rem;
    }
    .btn-wrapper {
      display: flex;
      flex-direction: row;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $teal-4;
        color: $teal-2;
        text-align: center;
        margin: 0 .33rem;
        padding: .75rem 1.2rem;
        font-size: 1.2rem;
        font-weight: 500;
        text-transform: uppercase;
        border: thin solid transparent;
        &.active {
          background-color: $sand;
          color: white;
        }
        &:hover {
          background-color: $teal-3;
          border-color: $teal-2;
          cursor: pointer;
        }
      }
    }
  }
}

.section-header {
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4rem;
  .title {
    font-size: 2rem;
    font-weight: 400;
  }
  .pagination-controls {
    .controls{
      display: inline-flex;
      height: 3rem;
      font-size: 1.2rem;
      line-height: 1;
      font-weight: 600;
      letter-spacing: .05em;
      border: none;
      padding: 0 1.5rem;
      border-radius: 8px;
      text-decoration: none;
      transition: all 0.3s ease;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      &:hover {
        color: $gray-3;
        background-color: $teal-3;
        cursor: pointer;
      }
    }
  }
}

.paginated-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border: 1px solid $teal-3;
  margin-bottom: 1rem;
  padding: .5rem;
  a {
    color: $teal-2;
  }
  &>span, &>a {
    flex: 1 1 auto;
    width: 20%;
  }
  .index {
    flex: 0 0 25px;
    min-width: unset !important;
  }
  .thumbnail {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $teal-4;
    margin-right: 1rem;
  }
  &:hover {
    background-color: #EFEFEF;
  }
}

.pagination-wrapper {
  .paginator {
    .count-input {
      select {
        background-color: white;
        border: 1px solid $teal-2;
        margin-right: 1rem;
        padding: .5rem;
        padding-left: 1rem;
        appearance: none;
        min-width: 75px;
        border-radius: 8px;
        background-image:
          linear-gradient(45deg, transparent 50%, gray 50%),
          linear-gradient(135deg, gray 50%, transparent 50%);
        background-position:
          calc(100% - 15px) calc(1em - 1px),
          calc(100% - 10px) calc(1em - 1px);
        background-size:
          5px 5px,
          5px 5px;
         background-repeat: no-repeat;
      }
    }
    .pages {
      .page {
        display: inline-flex;
        height: 3rem;
        font-size: 1.2rem;
        line-height: 1;
        font-weight: 600;
        letter-spacing: .05em;
        border: none;
        padding: 0 1.5rem;
        border-radius: 8px;
        text-decoration: none;
        transition: all 0.3s ease;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        margin-left: 1rem;
        background-color: rgba(200, 200, 200, .5);
        &.active {
          background-color: $teal-2;
        }
        &:hover {
          color: $gray-3;
          background-color: $teal-3;
          cursor: pointer;
        }
      }
    }
  }
  .pagination-controls {
    margin-bottom: 1rem;
    .control {
      .search-input-wrapper {
        input {
          border: 1px solid $teal-3;
          padding: .25rem .75rem;
        }
      }
      .control-icon {
        display: inline-flex;
        height: 3rem;
        font-size: 1.2rem;
        line-height: 1;
        font-weight: 600;
        letter-spacing: .05em;
        border: none;
        padding: 0 1.5rem;
        border-radius: 8px;
        text-decoration: none;
        transition: all 0.3s ease;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        margin-left: 1rem;
        &:hover {
          color: $gray-3;
          background-color: $teal-3;
          cursor: pointer;
        }
      }
    }
  }
}

.box {
  @include box;
}

.sidebar {
  @include respond-to-max('tablet') {
    // width: 10rem !important;
    // margin: 0 4rem auto 2rem;
    display: none;
  }
  // @include respond-to-max('mobile') {
  //   width: 6rem !important;
  // }
  .sidebar-box {
    @include box;
    padding: 2rem 0;
    border-radius: 4px;
    margin-bottom: 2rem;
    .display-value {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
      .label {
        font-size: 1.3rem;
      }
    }
  }
  .side-nav {
    // @include box;
    // flex-basis: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-left: 1px solid $teal-3;
    a {
      color: $teal-2;
      text-decoration: none;
      margin-left: -3px;
      font-weight: 700;
      color: $teal-3;
      border-left: 5px solid transparent;
      padding: .5rem 0 .5rem 2rem;
      @include respond-to-max('mobile') {
          font-size: 1.2rem;
      }
      svg {
        margin-right: .7rem;
        width: 3rem;
      }
      &.active {
        color: $teal-1;
        border-color: $teal-1;
      }
      &:hover {
        color: $teal-2;
        border-color: $teal-3;
      }
    }
  }
}

table {
  thead {
    background-color: $teal-3a;
    color: $teal-1;
  }
  tbody {
    background-color: white;
  }
}
