@import url('https://fonts.googleapis.com/css2?family=Cormorant:wght@300;400;600&family=Montserrat:wght@200;400;600&display=swap');
/* Base text styling */
body, html {
    font-weight: 200;
    font-family: 'Montserrat', sans-serif;
    font-size: 10px;
}

h1, h2, h3, h4, .h1, .h2, .h3, .h4 {
    font-weight: 400;
    color: #232323;
}

h1, .h1 {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: .5em;
}

h2, .h2 {
  font-size: 2.5rem;
  line-height: 1.21875;
}

h3, .h3 {
  font-size: 2rem;
  line-height: 1.25;
}

h4, .h4 {
  font-size: 1.5rem;
}

p, a, ul, li {
  font-size: 1.6rem;
  font-weight: 400;
}

.p-wrap {
  white-space: pre-line;
}

b, strong {
    font-weight: 800;
}

.alert, .error-notice {
  color: red;
}

.invisible {
  visibility: hidden;
}

.label-values {
  .values {
    white-space: pre-line;
  }
}

@include respond-to-max('laptop') {
  h1, .h1 {
    font-size: 2.15rem;
  }
}

@include respond-to-max('tablet') {
  h1, .h1 {
    text-align: center;
  }
}

@include respond-to-max('mobile') {
  h1, .h1 {
    text-align: center;
  }
}
