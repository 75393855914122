@import "_mixins";

#public-nav, #admin-nav {
    position: fixed;
    width: 100%;
    top: 0;
    background: white;
    z-index: 15;
    height: 100px;
    padding: 1rem;
    border-bottom: thin solid $teal-1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .public-menu, .admin-menu {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        a {
            font-size: 2rem;
            margin-left: 2rem;
            font-weight: 500;
            color: $teal-1;
            text-decoration: none;
            padding: 0 1rem;
            border-bottom: 3px solid transparent;
            @include respond-to-max('mobile') {
                line-height: 1.6;
                text-align: center;
            }
            &:hover {
                border-bottom: 3px solid $teal-3;
            }
            &.active {
                border-bottom: 3px solid $teal-1;
            }
            &.btn {
                background-color: $teal-2;
                color: white;
                border: 1px solid $teal-2;
                padding: .75rem 1.5rem;
                &:hover {
                    background-color: $teal-4;
                    color: $teal-2;
                }
            }
        }
    }
    .admin-menu {
        a {
            color: $peach;
        }
    }
    .public-menu {
        a {
            font-size: 1.5rem;
        }
    }
}

#admin-nav {
    border-bottom-color: $teal-4;
    @include shadow;
}

.menu {
    height: 5rem;
    padding: 2.5rem 1rem .5rem .5rem;
    position: relative;
    z-index: 10;
    .wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
        img {
            height: 4rem;
        }
        .links {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            a {
                font-size: 1.6rem;
                font-weight: 400;
                padding: .5rem;
                margin: 0 1rem;
                display: block;
                color: $teal-2;
                height: 100%;
                text-decoration: none;
                border-bottom: 2px solid transparent;
                &.active, &:hover, &:focus {
                    color: $teal-3;
                    border-bottom: 2px solid $teal-3;
                }
            }
            .btn {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }
}

.page-header {
    width: 100%;
    background-color: $teal-1;
    padding: 1rem 0;
    border-bottom: thin solid $teal-2;
    .container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .logo-holder {
            display: flex;
            flex-direction: column;
            align-items: center;
            .tagline {
                font-size: 1.5rem;
                letter-spacing: .05em;
                font-weight: 400;
                color: $teal-3;
            }
        }
    }
}

.chef-photo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    border: thin solid $teal-1;
    background-color: $teal-2;
    margin-right: 2rem;
    &:hover {
        transform: scale(1.05);
        cursor: pointer;
    }
    .initials {
        font-weight: 500;
        font-size: 3.5rem;
        letter-spacing: .1rem;
        padding: 1rem;
        color: white;
    }
}

