@import "_colors";
@import "_mixins";

.ticket-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    .info {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-start;
        font-weight: 400;
        color: $teal-2;

        h1 {
            font-size: 2.5rem;
            color: $teal-1;
            margin: 0;
            padding: 0;
            font-weight: 500;
            margin-right: 2rem;
            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }
        }

        .client {
            margin-right: 2rem;
        }

        .status {
            margin-left: 2rem;
            font-weight: 700;
        }
    }

    .actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        .lead-action {
            margin-right: 2rem;
            font-size: 2rem;
            position: relative;
            svg, i {
                color: $teal-2;
            }
            &:hover {
                cursor: pointer;
                svg, i {
                    color: $teal-1;
                }
            }
        }
    }
}

.event-block .actions .ticket-menu,
.ticket-header .actions .ticket-menu {
    @include shadow;
    position: absolute;
    top: 3rem;
    right: 0;
    z-index: 10;
    background-color: white;
    border-radius: 4px;
    border: thin solid $teal-3;
    display: flex;
    flex-direction: column;
    width: 160px;
    padding: .3rem 0;
    a,
    span {
        font-size: 1.3rem;
        text-decoration: none;
        color: $teal-1;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: .3rem 1rem;

        svg,
        i {
            margin-right: .5rem;
        }

        &:hover {
            cursor: pointer;
            background-color: $teal-4;
        }
    }
}
.event-block .actions .ticket-menu {
    top: .25rem;
    right: 3rem;
}

.cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    &.list {
        flex-direction: column;
        width: 100%;
        .card {
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: center;
            padding: 1rem 2rem 1rem 1rem;
            margin-bottom: 1.5rem;
            margin-right: 0;
            .initials-holder {
                position: absolute;
                height: 50px;
                width: 50px;
                top: calc(50% - 25px);
                left: -2.5rem;
                .initials {
                    font-size: 2rem;
                }
            }
            .menu-holder {
                top: 1rem;
                right: 1rem;
            }
            .title {
                font-size: 2rem;
                margin-left: 3rem;
                flex: 1 1 20%;
                padding-top: 0%;
            }
            .details-wrapper{
                flex: 1 1 20%;
                .details {
                    font-size: 1.5rem;
                }
                a {
                    color: $gray-1;
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            .btn{
                margin-top: 0px;
            }
        }
    }
    .card {
        background-color: white;
        padding: 4rem 3rem 3rem 3rem;
        margin-bottom: 3rem;
        min-width: 24%;
        display: flex;
        flex-direction: column;
        position: relative;
        margin-right: 2rem;
        .initials-holder {
            position: absolute;
            border: thin solid $teal-1;
            height: 60px;
            width: 60px;
            top: -30px;
            left: calc(50% - 30px);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            background-color: white;
            border-radius: 30px;
            .initials {
                font-weight: 500;
                font-size: 2.5rem;
                letter-spacing: .1rem;
            }
        }
        .menu-holder {
            position: absolute;
            top: 1rem;
            right: 1.5rem;
        }
        .title {
            font-size: 2rem;
            font-weight: 500;
            color: $teal-1;
            margin-bottom: .8rem;
        }
        .details-wrapper {
            display: flex;
            flex: 1 1 2rem;
            flex-direction: column;
        .details {
            display: flex;
            flex-direction: column;
            font-size: 1.5rem;
            font-weight: 400;
            }
        }
        .btn {
            margin-top: 3rem;
        }
    }
}

.meta-manager {
    background-color: white;
    padding: 2rem;
}

.ticket-items {
    display: flex;
    flex-direction: column;
    .item {
        background-color: white;
        padding: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
        .remove {
            margin-right: 1rem;
            color: $teal-3;
            &:hover {
                cursor: pointer;
                color: $teal-1;
            }
        }
        .field {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            &.name {
                flex-basis: 22.5%;
            }
            &.units,
            &.quantity {
                flex-basis: 12.5%;
            }
            &.party,
            &.category {
                flex-basis: 20%;
            }
            &.time,
            &.client-rate,
            &.actual-rate,
            &.margin {
                flex-basis: 15%;
            }
            &.notes {
                flex: 1 1 0;
            }
            .label {
                font-size: 1.2rem;
                text-transform: uppercase;
                color: $teal-2;
            }
            .values {
                white-space: normal;
            }
        }
        form {
            width: 100%;
            &.field {
                label {
                    font-size: 1.2rem;
                    font-weight: 200;
                    text-transform: uppercase;
                    letter-spacing: normal;
                    color: $teal-2;
                }
                .input-wrapper {
                    padding: 0;
                    padding-right: 1rem;
                }
                .form-group {
                    position: relative;
                    .form-btn-wrapper {
                        position: absolute;
                        bottom: -3rem;
                        width: fit-content;
                        min-width: 130px;
                        background-color: white;
                        padding: .25rem;
                        border: thin solid $teal-4;
                        @include shadow;
                        .btn {
                            padding: .5rem 1rem;
                            margin-right: .5rem;
                            &.add {
                                background-color: $teal-4;
                                color: $teal-1;
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}