@import "_colors";

.lead-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    .info {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-start;
        font-weight: 400;
        color: $teal-2;
        h1 {
            font-size: 2.5rem;
            color: $teal-1;
            margin: 0;
            padding: 0;
            font-weight: 500;
            margin-right: 2rem;
        }
        .client {
            margin-right: 2rem;
        }
        .status {
            margin-left: 2rem;
            font-weight: 700;
        }
    }
    .actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        .lead-action {
            margin-right: 2rem;
            font-size: 2rem;
            svg, i {
                color: $teal-2;
            }
            &:hover {
                cursor: pointer;
                svg, i {
                    color: $teal-1;
                }
            }
        }
    }
}