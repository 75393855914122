@import "_colors";

.search-complete-wrapper {
    position: relative;
    .results-list {
        position: absolute;
        z-index: 2;
        top: 3rem;
        left: 0;
        background-color: white;
        border: 1px solid $teal-2;
        .result-item {
            padding: .5rem 1rem;
            border-bottom: 1px solid $teal-2;
            &:hover {
                cursor: pointer;
                background-color: $teal-4;
            }
        }
    }
}