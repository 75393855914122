@import "_colors";
@import "_mixins";

#admin-drawer {
    .closer {
        position: absolute;
        top: 1rem;
        right: 1rem;
        &:hover {
            cursor: pointer;
            color: $teal-3;
        }
    }
    .notes-title {
        color: $teal-1;
        font-size: 1.5rem;
        font-weight: 500;
        margin-bottom: 1rem;
    }
    .messages-wrapper {
        display: flex;
        flex: 1 1 auto;
        // border: 1px solid;
        overflow-y: auto;
        @include scrollbars();
        .messages {
            .message {
                display: flex;
                flex-direction: column;
                margin-bottom: 1.5rem;
                .sender {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    margin-bottom: .5rem;
                    .initials {
                        width: 26px;
                        height: 26px;
                        background-color: $teal-1;
                        color: white;
                        border-radius: 50%;
                        justify-content: center;
                        align-items: center;
                        display: flex;
                        margin-right: .7rem;
                        font-size: 1.2rem;
                    }
                    .name {
                        font-weight: 400;
                        font-size: 1.4rem;
                    }
                }
                .note {
                    color: black;
                    font-weight: 400;
                    font-size: 1.4rem;
                    margin-bottom: .5rem;
                }
                .date {
                    font-size: 1.1rem;
                }
                .category {
                    font-size: 1.2rem;
                    background-color: $teal-4;
                    width: fit-content;
                    padding: .15rem .5rem;
                    border-radius: 2px;
                    font-weight: 300;
                }
            }
        }
    }
    .input-wrapper {
        background-color: $teal-3;
        margin-top: 1rem;
        // padding: 1rem;
        .wysiwyg {
            position: relative;
            margin-bottom: .5rem;
            overflow-y: auto;
            @include scrollbars();
            .input-area, .shadow-content {
                position: absolute;
                top: 0;
                left: 0;
                font-size: 1.4rem;
                font-weight: 400;
                padding: .7rem;
                line-height: 1.3;
            }
            .input-area {
                color: transparent;
                caret-color: black;
                height: 100%;
                width: 100%;
                &::before {
                    content: 'Start typing...';
                    display: block;
                    color: $gray-5;
                    font-style: italic;
                    font-weight: 300;
                }
            }
            &:focus-within, &.has-content {
                .input-area {
                    &::before {
                        content: '';
                        display: none;
                    }
                }
            }
            .shadow-content {
                color: $gray-1;
                position: relative;
                max-height: 100px;
                min-height: 2.5rem;
                .mention {
                    color: $teal-1;
                }
                .category {
                    color: $teal-2;
                }
            }
        }
        
        .submit-area {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 0 .7rem .5rem .7rem;
            .instruction {
                color: $teal-2;
                font-size: 1.2rem;
                font-weight: 400;
            }
            .submit-btn {
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}