.dashboard-wrapper {
    display: flex;
    justify-content: space-between;
    .sidebar {
        width: $sidebarWidth;
    }
    .main {
        // width: calc(100% - #{$sidebarWidth + $sidebarGutter});
        width: 100%;
        h2 {
            color: $teal-1;
            margin-bottom: 1rem;
        }
        .content-box {
            @include box;
            margin-bottom: 2rem;
            h3 {
                color: $teal-2;
            }
            .content-box {
                box-shadow: none;
            }
        }
        .field-wrappers {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            &.flex-50 {
                & > div, & > form {
                    flex-basis: 50%;
                }    
            }
            &.flex-100 {
                & > div, & > form {
                    flex-basis: 100%;
                }    
            }
            & > div, & > form {
                flex-basis: 33%;
                .input-wrapper {
                    padding: 0 1rem 0 0;
                }
                &.flex-100 {
                    flex-basis: 100%;
                }
            }
            .label {
                font-size: 1.25rem;
                text-transform: uppercase;
                color: $teal-3;
            }
        }
    }
}

.columns {
    display: flex;
    flex-wrap: wrap;
    & > div {
        flex: 1 1 45%;
        width: 45%;
        &:first-child {
            margin-right: 2.5%;
        }
        &:last-child {
            margin-left: 2.5%;
        }
    }
}